html {
    &.no-scroll {
        #root {
            overflow: hidden;
        }
    }
}

#components-layout {
    .trigger {
        font-size: 18px;
        line-height: 64px;
        padding: 0 24px;
        cursor: pointer;
        transition: color 0.3s;

        &:hover {
            color: #1890ff;
        }
    }

    .ant-layout {
        margin-left: 200px;

        &:before {
            content: "";
            display: block;
            width: 100%;
            height: 100%;
            opacity: 0;
            visibility: hidden;
            position: fixed;
            top: 0;
            left: 0;
            background-color: transparent;
            z-index: 9999;
            transition: all linear 0.2s;
        }

        @media (max-width: 1200px) {
            margin-left: 0;
            transform: translateX(200px);
        }

        &-sider {
            position: fixed !important;
            height: 100%;
            z-index: 1;

            .logo {
                padding-top: 90px;
                text-align: center;
                background: url("assets/logo.png") no-repeat center top / 75px;
                margin: 16px 16px 36px;
                transition: all linear 0.2s;

                span {
                    line-height: 15px;
                    font-size: 15px;
                    color: rgba(255, 255, 255, 0.65);
                    white-space: nowrap;
                    transition: all linear 0.2s;
                    display: block;
                }
            }

            @media (max-width: 1200px) {
                &:not(.ant-layout-sider-collapsed) {
                    & + .ant-layout {
                        &:before {
                            opacity: 1;
                            visibility: visible;
                            background-color: rgba(#000, 0.5);
                        }
                    }
                }
            }
        }

        &-sider-collapsed {
            position: relative;

            .logo {
                padding-top: 45px;
                background-size: 45px;

                span {
                    opacity: 0;
                    visibility: hidden;
                    height: 0;
                }
            }

            & + .ant-layout {
                margin-left: 0;

                @media (max-width: 1200px) {
                    transform: translateX(0);
                }
            }
        }

        &-header {
            padding: 0 15px;
        }
    }

    .ant-alert {
        margin-bottom: 20px !important;

        &:last-child {
            margin-bottom: 0 !important;
        }

        &-message,
        &-description {
            p {
                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
    }

    .ant-collapse {
        margin-bottom: 25px !important;

        & > .ant-collapse-item {
            & > .ant-collapse-header {
                font-size: 18px;
                font-weight: bold;

                h4 {
                    margin-bottom: 0;
                    color: #3790FF !important;

                    @media (max-width: 576px) {
                        font-size: 18px;
                    }

                    .ant-typography {
                        kbd {
                            @media (max-width: 576px) {
                                font-size: 13px;
                            }
                        }
                    }
                }
            }
        }

        &-content {
            & > .ant-collapse-content-box {
                p {
                    &:last-child {
                        margin-bottom: 0;
                    }
                }
            }
        }
    }
}

.site-layout {
    transition: all linear 0.2s;

    &__header {
        background: #fff !important;

        @media (max-width: 576px) {
            text-align: center;
        }
    }

    &__content {
        margin: 25px 15px 0 !important;
        padding: 25px !important;
        min-height: 280px !important;
        background: #fff !important;

        @media (max-width: 576px) {
            padding: 15px !important;
        }
    }
}

blockquote {
    padding: 0 0 0 15px;
    border-left: 5px solid #eee;
}

.starred-note {
    margin-top: 15px !important;
}

.ant-alert {
    p {
        &:last-child {
            margin-bottom: 0 !important;
        }
    }
}
